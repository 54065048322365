export default [
  {
    title: 'User Management',
    icon: 'UsersIcon',
    children: [
      {
        title: 'List User',
        route: 'users',
      },
      {
        title: 'List Role',
        route: 'roles',
      }
    ]
  },
  {
    title: 'Master Data',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Category Indicator',
        route: 'category_indicators',
      },
      {
        title: 'Indicator',
        route: 'indicators',
      },
      {
        title: 'Supported By',
        route: 'partners',
      },
      {
        title: 'Province',
        route: 'provinces',
      },
      {
        title: 'District',
        route: 'districts',
      },
    ],
  },
  {
    title: 'Data Management',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Data Indicator',
        route: 'indicator_datas',
      },
    ],
  },
]
